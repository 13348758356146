import React from 'react';
import {ISliderHeaderProps} from './interfaces';
import './styles.scss';
const SliderHeader: React.FC<ISliderHeaderProps> = (
    {headerString, imgSrc, altText}:ISliderHeaderProps,
) =>(
  <div className="slider-header">
    <img
      src={imgSrc}
      alt={altText}
      className="header-image"
    />
    <h2 className="slide-title">
      {headerString}
    </h2>
  </div>
);

export default SliderHeader;
