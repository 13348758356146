import React from 'react';
import './styles.scss';
import {Gallery, Item} from 'react-photoswipe-gallery';
import {ImageConstants} from './constants';
import {Link} from 'react-router-dom';
import RoutingConstants from '../../Navigation/Constants';

const SmallRoom: React.FC = ():JSX.Element => {
  return (
    <>
      <Gallery>
        {
          Object.values(ImageConstants).map((image, index) => {
            return (
              <Item
                original={image.imgSrc}
                thumbnail={image.imgSrc}
                width="1024"
                height="768"
                key={image.imgSrc}
              >
                {({ref, open}) => (
                  <img
                    className="gallery-image"
                    // @ts-ignore
                    ref={ref}
                    onClick={open}
                    src={image.imgSrc}
                  />
                )}
              </Item>);
          })
        }
      </Gallery>
      <div className="back-button-container">
        <Link className="back-button" to={RoutingConstants.home.path}>
            Volver
        </Link>
      </div>
    </>
  );
};

export default SmallRoom;
