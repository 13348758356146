import wiFi from '../../../../../assets/icons/wifi.png';
import bed from '../../../../../assets/icons/bed.png';
import pingPong from '../../../../../assets/icons/table-tennis.png';

interface IConstants {
    [key: string]: {
        icon: string,
        text: string,
        alt: string
    }
}

export const title:string = '¿Qué tenemos para ti?';

export const CircleConstants: IConstants ={
  rooms: {
    text: 'Habitaciones que se acomodan a tu presupuesto totalmente ' +
        'equipadas, modernas, amobladas y  cada una con diseños ' +
        'vanguardistas según tu gusto.',
    icon: bed,
    alt: 'bed',
  },
  entertainment: {
    text: 'Zonas comunes pensadas para el descanso, la diversión' +
        ', el trabajo y el estudio. Espacios pensados en ' +
        'la comodidad y tranquilidad',
    icon: pingPong,
    alt: 'ping pong',
  },
  services: {
    text: 'Servicios pensados en tu comodidad y bienestar: Internet ' +
        'de alta velocidad Wi-Fi en todos los espacios, Servicios públicos ' +
        'incluidos, Aseo de habitación, Zona de ' +
        'lavandería, BBQ, silla de masajes',
    icon: wiFi,
    alt: 'Wi Fi',
  },
};

export const sectionFooter = 'En Casa Cabrera trabajamos por y para tu ' +
    'bienestar, por esto contamos con un grupo de ' +
    'colaboradores que en todo momento estarán pendientes de ' +
    'resolver tus inquietudes, atención 24/7.';
