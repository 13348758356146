import React, {useEffect, useState} from 'react';
import SliderContentImages from '../Components/Constants';
import Dots from '../Components/Dots';
import Arrows from '../Components/Arrows';
import Slide from '../Components/Slide';
import './styles.scss';

const ImageSlider: React.FC = (

): JSX.Element =>{
  const sliderLength = SliderContentImages.length - 1;
  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(()=>{
    const interval = setInterval(
        ()=> setActiveIndex(activeIndex === sliderLength ? 0 : activeIndex + 1),
        100000000,
    );

    return (
      () =>{
        clearInterval(interval);
      }
    );
  },
  [activeIndex]);

  return <div
    className="slider-container"
  >
    <Slide activeIndex={activeIndex} />
    <Arrows
      prevSlide={
        ()=> setActiveIndex(activeIndex < 1? sliderLength: activeIndex - 1)
      }
      nextSlide={
        ()=> setActiveIndex(activeIndex === sliderLength ? 0 : activeIndex + 1)
      }
    />
    <Dots activeIndex={activeIndex} />
  </div>;
};

export default ImageSlider;
