import React from 'react';

import './styles.scss';

export interface ICircleProps {
    text: string,
    icon: string,
    alt: string
}


export const CircleElement: React.FC<ICircleProps> = ({
  icon, text, alt,
}:ICircleProps,
):JSX.Element => (
  <div
    className='circle-info-container'
  >
    <div className="icon-container">
      <img className="icon" src={icon} alt={alt}/>
    </div>
    <p
      className="paragraph"
    >
      {text}
    </p>
  </div>
);

export default CircleElement;
