interface IRoomHeader{
    [key:string]: {
        key: string,
        value: string
    }
}

export const RoomHeaderConstants: IRoomHeader = {
  paragraph1: {
    key: 'room-praragraph1',
    value: 'Estamos cerca del centro de Bogotá, importantes ' +
        'centros comerciales, supermercados, universidades,' +
        ' clinicas y hospitales.',
  },
  paragraph2: {
    key: 'room-praragraph2',
    value: 'Diseñamos espacios amplios, comodos y cálidos, ' +
        'contamos con 12 habitaciones ' +
              'totalmente privadas, las cuales nos permiten ' +
          'crear espacios de convivencia y tranquilidad.',
  },
};
