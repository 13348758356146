import React from 'react';

import logoImage from '../../assets/logos/casacabrera-7.svg';
import instagram from '../../assets/icons/instagram.png';
import facebook from '../../assets/icons/facebook.png';
import email from '../../assets/icons/email.png';
import phone from '../../assets/icons/phone-call.png';
import './styles.scss';

const Footer: React.FC = ():JSX.Element => (
  <footer
    className="footer"
  >
    <div className="logo-container">
      <img
        src={logoImage}
        alt="Casa cabrera una experiencia diferente"
        className="logo"
      />
    </div>
    <div className="social-media-container">
      <h4>Siguenos en </h4>
      <a href="https://www.instagram.com/casacabrera2021/" target="_blank" rel="noreferrer">
        <img src={instagram} alt="instagram" className="icon"/>
      </a>
      <a href="https://www.facebook.com/CasaCabrera2021-104606021799706" target="_blank" rel="noreferrer">
        <img src={facebook} alt="facebook" className="icon"/>
      </a>
    </div>
    <div className="contact-us">
      <img src={phone} alt="phone numbers" className="icon"/>
      <div>
        <p className="title">Llámanos:</p>
        <p>315 299 3743</p>
        <p>315 278 5748</p>
      </div>
    </div>
    <div className="mail-container">
      <img src={email} alt="phone numbers" className="icon"/>
      <div>
        <p className="title">Escribenos: </p>
        <p>contacto@casa-cabrera.co</p>
        <p>Calle 50 # 17-36</p>
      </div>
    </div>
  </footer>
);

export default Footer;
