import React from 'react';
import SliderContentImages from '../Constants';
import {IDotsProps} from './interfaces';
import './styles.scss';

const Dots: React.FC<IDotsProps> = ({activeIndex}:IDotsProps):JSX.Element => (
  <div className="all-dots">
    {
      SliderContentImages.map((singleSlide, index) =>(
        <span
          key={singleSlide.title}
          className={activeIndex === index? 'dot active': 'dot'}
        />
      ))
    }
  </div>
);

export default Dots;
