import React from 'react';
import {Route, Switch} from 'react-router-dom';
import RoutingConstants from '../Constants/';
import Footer from '../../Components/Footer';

const RouterConfig: React.FC = (): JSX.Element => {
  return (
    <>
      <Switch>
        {
          Object.values(RoutingConstants).map((route) => (
            <Route
              exact
              key={route.path}
              path={route.path}
              component={route.component}
            />
          ))
        }
      </Switch>
      <Footer/>
    </>
  );
};
export default RouterConfig;
