import React from 'react';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';

import {Gallery, Item} from 'react-photoswipe-gallery';
import {ImageConstants} from './Constants';
import './styles.scss';

const MyGallery: React.FC = (): JSX.Element => (
  <Gallery>
    {
      Object.values(ImageConstants).map((image, index) => {
        if (index !== 0) {
          return (
            <Item
              original={image.imgSrc}
              thumbnail={image.imgSrc}
              width="1024"
              height="768"
              key={image.imgSrc}
            >
              {({ref, open}) => (
                <img
                  className="gallery-image"
                  // @ts-ignore
                  ref={ref}
                  onClick={open}
                  src={image.imgSrc}
                />
              )}
            </Item>);
        }
      })
    }
  </Gallery>
);

export const AloneImage: React.FC = (): JSX.Element => (
  <Gallery>
    {
      Object.values(ImageConstants).map((image, index) => {
        if (index === 0) {
          return (
            <Item
              original={image.imgSrc}
              thumbnail={image.imgSrc}
              width="1024"
              height="768"
              key={image.imgSrc}
            >
              {({ref, open}) => (
                <img
                  className="gallery-image first"
                  // @ts-ignore
                  ref={ref}
                  onClick={open}
                  src={image.imgSrc}
                />
              )}
            </Item>);
        }
      })
    }
  </Gallery>
);

export default MyGallery;
