import HomePage from '../../Pages/Home/src';
import SmallRoom from '../../Pages/SmallRoom';
import MediumRoom from '../../Pages/MediumRoom';
import BigRoom from '../../Pages/BigRoom';
import React from 'react';

interface RoutingConstants {
  [key:string]:{
    name: string,
    path: string,
    component: React.FC,
  }
}

const RoutingConstants: RoutingConstants = {
  home: {
    name: 'Home',
    path: '/',
    component: HomePage,
  },

  smallRoom: {
    name: 'Small Room',
    path: '/small',
    component: SmallRoom,
  },

  mediumRoom: {
    name: 'Medium Room',
    path: '/medium',
    component: MediumRoom,
  },

  bigRoom: {
    name: 'Big Room',
    path: '/big',
    component: BigRoom,
  },
};

export default RoutingConstants;
