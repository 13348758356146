import React from 'react';
import ImageSlider from '../Components/ImageSlider/src';
import RoomsSections from '../Components/RoomsContainer/src';
import WhatWeOffer from '../Components/WhatWeOffer/src';
import MyGallery, {AloneImage} from '../Components/Gallery/src';

import './styles.scss';


const HomePage: React.FC = ():JSX.Element => {
  return (
    <main className="main-app-container">
      <div className="image-slider-container">
        <ImageSlider/>
      </div>
      <div className="rooms-container">
        <RoomsSections/>
      </div>
      <div className="what-we-offer-container">
        <WhatWeOffer/>
      </div>
      <div className="gallery-container">
        <div className="alone-image">
          <AloneImage />
        </div>
        <div className="multiple-images">
          <MyGallery />
        </div>
      </div>
    </main>
  );
};

export default HomePage;
