import React from 'react';
import {title, CircleConstants, sectionFooter} from './constants';
import CircleElement from '../Components/RoundCircle';

import './styles.scss';

const WhatWeOffer: React.FC = ():JSX.Element => (
  <section
    className="what-we-offer-section-container"
  >
    <h3 className="section-title">{title}</h3>
    <div className="dots-container">
      {
        Object.values(CircleConstants).map(
            ({text, alt, icon}) => (
              <CircleElement
                text={text}
                alt={alt}
                icon={icon}
                key={icon}
              />
            ),
        )
      }
    </div>
    <p className="section-paragraph">{sectionFooter}</p>
  </section>
);

export default WhatWeOffer;
