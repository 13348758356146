import logo from '../../../../../../assets/logos/casacabrera.svg';
import backgroundImage1
  from '../../../../../../assets/HouseImages/ZonasComunes/CommonZone0.jpg';
import backgroundImage2
  from '../../../../../../assets/HouseImages/ZonasComunes/CommonZone16.jpg';
import backgroundImage3
  from '../../../../../../assets/HouseImages/ZonasComunes/CommonZone29.jpg';
import backgroundImage4
  from '../../../../../../assets/HouseImages/ZonasComunes/CommonZone39.jpg';

interface ISingleSlide{
    title: string,
    backgroundImage: string,
    logoImage: string,
    subTitle?: string,
    text?: string,
    altText: string,
    className: string
}

const SliderContentImages: Array<ISingleSlide> = [
  {
    logoImage: logo,
    backgroundImage: backgroundImage1,
    title: 'Una Experiencia Diferente',
    text: '',
    subTitle: '',
    altText: 'this is the alt text',
    className: 'first',
  },
  {
    logoImage: logo,
    backgroundImage: backgroundImage2,
    title: 'Familia',
    text: '',
    subTitle: '',
    altText: 'this is the alt text',
    className: 'second',
  },
  {
    logoImage: logo,
    backgroundImage: backgroundImage3,
    title: 'Calidez',
    text: '',
    subTitle: '',
    altText: 'this is the alt text',
    className: 'third',
  },
  {
    logoImage: logo,
    backgroundImage: backgroundImage4,
    title: '',
    text: '',
    subTitle: '',
    altText: 'this is the alt text',
    className: 'fourth',
  },
];

export default SliderContentImages;
